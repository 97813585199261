export const MULTI_DESKS = 'MULTI_DESKS'
export const WRONG_SLOT = 'WRONG_SLOT'
export const NOT_COMPLETED = 'NOT_COMPLETED'
export const ERROR_APPEARED = 'ERROR_APPEARED'
export const REACHED_CAPACITY = 'REACHED_CAPACITY'
export const BOOKING_REGISTERED = 'BOOKING_REGISTERED'
export const ATTENDANCE_REGISTERED = 'ATTENDANCE_REGISTERED'
export const BOOKING_REMOVED = 'BOOKING_REMOVED'
export const ATTENDANCE_REMOVED = 'ATTENDANCE_REMOVED'
export const DOUBLE_BOOKING = 'DOUBLE_BOOKING'
export const BOOKINGS_REGISTERED = 'BOOKINGS_REGISTERED'
export const INVALID_BOOKINGS = 'INVALID_BOOKINGS'
