import React from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'

import {
  WRONG_SLOT,
  NOT_COMPLETED,
  ERROR_APPEARED,
  BOOKING_REGISTERED,
  BOOKING_REMOVED,
  DOUBLE_BOOKING,
  BOOKINGS_REGISTERED,
  INVALID_BOOKINGS,
} from 'utils/infoMessages'

const rollIn = keyframes`
  from {
    transform: scaleX(0);
    opacity: 0;
  }
  to {
    transform: scaleX(1);
    opacity: 1;
  }
`
const rollOut = keyframes`
  from {
    transform: scaleX(1);
    opacity: 1;
  }
  to {
    transform: scaleX(0);
    opacity: 0;
  }
`
const TextHolder = styled.div`
  animation: ${(props) => (props.showMessage ? rollIn : rollOut)} 0.3s forwards;
  transform-origin: left center;
  padding: 10px 20px 10px 10px;
  width: auto;
  position: ${(props) => (props.isBookingMessage ? 'static' : 'relative')};
  border-radius: 3px;
  right: 40px;
  top: 14px;
  font-size: 14px;
  font-weight: 200;
  display: flex;
  max-width: 440px;
  box-shadow: ${(props) =>
    props.isBookingMessage ? '0' : ' 0 2px 4px 0 rgba(140, 139, 139, 0.27)'};
  align-items: center;
  z-index: 1;
  margin: 0;
  ${(props) =>
    (props.showMessage === BOOKING_REGISTERED ||
      props.showMessage === BOOKINGS_REGISTERED) &&
    css`
      border-left: 7px solid #148368;
      background-color: #0e9571;
      color: white;
    `};
  ${(props) =>
    props.showMessage === BOOKING_REMOVED &&
    css`
      border: 2px solid #2f3437;
      border-left: 7px solid #2f3437;
      background-color: #fff;
      color: #171717;
    `};
  ${(props) =>
    (props.showMessage === WRONG_SLOT ||
      props.showMessage === NOT_COMPLETED ||
      props.showMessage === ERROR_APPEARED ||
      props.showMessage === DOUBLE_BOOKING ||
      props.showMessage === INVALID_BOOKINGS) &&
    css`
      border-left: 7px solid #ffe564;
      background-color: #fffae2;
      color: ${(props) => props.theme.darkText};
    `};
`
const TextValue = styled.p`
  line-height: 20px;
  margin: 0;
`
const IconHolder = styled.div`
  margin: 0px 2px;
  width: 40px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
  ${(props) =>
    (props.showMessage === BOOKING_REGISTERED ||
      props.showMessage === BOOKINGS_REGISTERED) &&
    css`
      background-image: url(./common-icons/checked_white.svg);
    `};
  ${(props) =>
    props.showMessage === BOOKING_REMOVED &&
    css`
      background-image: url(./common-icons/plus2.svg);
      background-size: 19px 21px;
    `};
  ${(props) =>
    (props.showMessage === WRONG_SLOT ||
      props.showMessage === NOT_COMPLETED ||
      props.showMessage === ERROR_APPEARED ||
      props.showMessage === DOUBLE_BOOKING ||
      props.showMessage === INVALID_BOOKINGS) &&
    css`
      background-image: url(./common-icons/info2.svg);
    `};
`

const ModalInfoBox = ({ showMessage, isBookingMessage }) => {
  const textInThePopup = () => {
    switch (showMessage) {
      case BOOKING_REGISTERED:
        return <Translate id="quickView.bookingSuccess" />
      case BOOKING_REMOVED:
        return <Translate id="quickView.meeting.cancelConfirmed" />
      case WRONG_SLOT:
        return <Translate id="quickView.wrongTimeInterval" />
      case NOT_COMPLETED:
        return <Translate id="quickView.notCompletedBooking" />
      case DOUBLE_BOOKING:
        return <Translate id="quickView.doubleBookingError" />
      case ERROR_APPEARED:
        return <Translate id="quickView.errorBooking" />
      case BOOKINGS_REGISTERED:
        return <Translate id="quickView.multipleBookingSuccess" />
      case INVALID_BOOKINGS:
        return <Translate id="quickView.invalidBookings" />

      default:
        return null
    }
  }
  return (
    <TextHolder isBookingMessage={isBookingMessage} showMessage={showMessage}>
      <IconHolder showMessage={showMessage} />
      <TextValue>{textInThePopup()}</TextValue>
    </TextHolder>
  )
}

ModalInfoBox.propTypes = {
  showMessage: PropTypes.string,
  isBookingMessage: PropTypes.bool,
}
const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, null)(ModalInfoBox)
