import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getRegisteredSlot } from 'containers/quickView/selectors'
import { setDeleteOnBehalfPermission } from 'containers/quickView/spaceModal/claimFlow/actions'
import {
  openBookingUI,
  closeBookingUI,
  meetingRequest,
} from 'containers/quickView/spaceModal/bookingFlow/actions'
import { clearQuickView } from 'containers/quickView/actions'
import BookingSelect from 'containers/quickView/spaceModal/bookingFlow/BookingSelect'
import { startWayfinding } from 'containers/app/actions'

class BookingFlow extends Component {
  handleDelete = () => {
    const { space, event, setDeleteOnBehalfPermission } = this.props
    const { id: eventId, start, end } = event
    setDeleteOnBehalfPermission(space.id, eventId, start, end)
  }

  handleClose = () => {
    const { closeBookingUI } = this.props
    closeBookingUI()
  }

  handleOpen = () => {
    const { openBookingUI } = this.props
    openBookingUI()
  }

  handleConfirm = () => {
    const { clearQuickView } = this.props
    clearQuickView()
  }

  handleBooking = (diff, subject, userId) => {
    const { space, meetingRequest, registeredSlot } = this.props
    meetingRequest(space.id, registeredSlot, subject, userId)
  }

  renderView = () => {
    const { space } = this.props

    return (
      <BookingSelect
        space={space}
        onClose={this.handleClose}
        onBooking={this.handleBooking}
      />
    )
  }

  render() {
    return this.renderView()
  }
}

BookingFlow.propTypes = {
  clearQuickView: PropTypes.func,
  closeBookingUI: PropTypes.func,
  meetingRequest: PropTypes.func,
  openBookingUI: PropTypes.func,
  space: PropTypes.object,
  event: PropTypes.object,
  setDeleteOnBehalfPermission: PropTypes.func,
  registeredSlot: PropTypes.object,
}

const mapStateToProps = (state) => ({
  registeredSlot: getRegisteredSlot(state),
})

export default connect(mapStateToProps, {
  closeBookingUI,
  openBookingUI,
  meetingRequest,
  clearQuickView,
  startWayfinding,
  setDeleteOnBehalfPermission,
})(BookingFlow)
