import countries from 'i18n-iso-countries'
import english from 'i18n-iso-countries/langs/en.json'
import french from 'i18n-iso-countries/langs/fr.json'
import swedish from 'i18n-iso-countries/langs/sv.json'
import norvegian from 'i18n-iso-countries/langs/no.json'
import german from 'i18n-iso-countries/langs/de.json'
import spanish from 'i18n-iso-countries/langs/es.json'
import lithuanian from 'i18n-iso-countries/langs/lt.json'

countries.registerLocale(english)
countries.registerLocale(french)
countries.registerLocale(swedish)
countries.registerLocale(norvegian)
countries.registerLocale(german)
countries.registerLocale(spanish)
countries.registerLocale(lithuanian)

const chinaTranslations = {
  en: 'China',
  sv: 'Kina',
  de: 'China',
  es: 'China',
  no: 'Kina',
  fr: 'Chine',
  lt: 'Kinija',
}

const taiwanTranslations = {
  en: 'Taiwan',
  sv: 'Taiwan',
  de: 'Taiwan',
  es: 'Taiwán',
  no: 'Taiwan',
  fr: 'Taiwan',
  lt: 'Taivanas',
}

export const getCountryName = (countryCode, selectedLanguage) => {
  //Requested override of country names
  if (countryCode === 'CN') return chinaTranslations[selectedLanguage]
  if (countryCode === 'TW') return taiwanTranslations[selectedLanguage]
  return countries.getName(countryCode, selectedLanguage, {
    select: 'official',
  })
}
