import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { format, fromUnixTime } from 'date-fns'
import englishLocale from 'date-fns/locale/en-US'
import swedishLocale from 'date-fns/locale/sv'
import norwegianLocale from 'date-fns/locale/nb'
import frenchLocale from 'date-fns/locale/fr'
import germanLocale from 'date-fns/locale/de'
import spanishLocale from 'date-fns/locale/es'
import lithuanianLocale from 'date-fns/locale/lt'
import { Translate } from 'react-localize-redux'

import ColleaguePicture from 'containers/flowPlanner/ColleaguePicture'
import StatisticsItem from 'containers/flowPlanner/StatisticsItem'
import { deleteMessageToShow } from 'containers/flowPlanner/actions'
import FlowPlannerProfilePicture from 'containers/quickView/FlowPlannerProfilePicture.jsx'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import { getSelectedColleagues } from 'containers/searchPanel/results/selectors'
import Icon from 'components/Icon'
import AttendanceInfoBox from 'containers/flowPlanner/AttendanceInfoBox'
import {
  getAttendanceDetailsUsersOnly,
  getSelfAttendances,
} from 'containers/flowPlanner/selectors'
import { getLocalDate } from 'utils/utilsFunctions'
import {
  sortByTypeAndName,
  mapAttendanceTypes,
} from 'containers/flowPlanner/flowplannerServices'
import {
  SAME_BUILDING,
  OTHER_BUILDING,
  VACATION,
  HOME,
  OFFICE,
  DESK,
  ALLOW_TO_OPEN_ATTENDANCE_POPUP,
} from 'utils/appVars'
import { getCurrentBuildingId } from 'containers/app/selectors'

const localeMap = {
  en: englishLocale,
  sv: swedishLocale,
  no: norwegianLocale,
  fr: frenchLocale,
  de: germanLocale,
  es: spanishLocale,
  lt: lithuanianLocale,
}

const CardItem = styled.div`
  height: 115px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-left: 2px solid #fff;
  :after {
    content: '';
    border-bottom: solid 1px #d5d5d7;
    position: absolute;
    width: 100%;
    left: 2px;
  }
  ${(props) =>
    props.active &&
    css`
      background-color: #f5fafe;
      border-left: 2px solid #0178d4;
    `};
`
const FullListIcon = styled(Icon)`
  transform: rotate(180deg);
  font-size: 21px;
  line-height: 1.3;
`

const MainDataHolder = styled.div`
  height: 70%;
  display: flex;
  justify-content: space-between;
`
const DateHolder = styled.div`
  height: 100%;
  width: 60px;
  margin-left: 4px;
  p {
    margin: 0 0 0 2px;
    font-family: Roboto;
    font-size: 14px;
    color: ${(props) => props.theme.darkText};
  }
  p:first-child {
    margin-top: 31px;
    text-transform: uppercase;
  }
`
const ColleaguesHolder = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  width: calc(100% - 95px);
  justify-content: flex-end;
  margin-right: 15px;
  > div {
    width: 51px;
    height: 51px;
    font-size: 18px;
    margin: 21px 0 0 8px;
  }
`
const StatisticsHolder = styled.div`
  height: 30%;
  line-height: 2;
  display: flex;
  justify-content: start;
  font-size: 12px;
  font-weight: normal;
  div {
    margin-left: 25px;
    margin-top: 4px;
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 4px;
    }
    &:last-child {
      right: 0;
      position: absolute;
    }
  }
`

const RegisterAttendanceHolder = styled.div.attrs({
  className: ALLOW_TO_OPEN_ATTENDANCE_POPUP,
})`
  width: 50px;
  height: 100%;
`
const RegisterAttendance = styled.button`
  width: 40px;
  height: 40px;
  border: solid 1px #2f3437;
  outline: none;
  line-height: 25px;
  font-size: 35px;
  background-color: #fff;
  border-radius: 21px;
  padding-left: 4px;
  margin-top: 28px;
  background-size: 49px;
  background-position: center;
  background-repeat: no-repeat;
  ${(props) =>
    !props.registered &&
    css`
      background-image: url(./common-icons/plus_circle2.svg);
    `};
  ${(props) =>
    props.registered &&
    css`
      background-color: #148368;
      border-color: #148368;
    `};
  ${(props) =>
    props.registered &&
    props.type === HOME &&
    css`
      background-image: url(home_white.svg);
      background-size: 20px;
    `};
  ${(props) =>
    props.registered &&
    props.type === VACATION &&
    css`
      background-image: url(vacation_white.svg);
      background-size: 35px;
    `};
  ${(props) =>
    props.registered &&
    props.type === SAME_BUILDING &&
    css`
      background-image: url(same_building_white.svg);
      background-size: 19px;
    `};
  ${(props) =>
    props.registered &&
    props.type === OTHER_BUILDING &&
    css`
      background-image: url(other_building_white.svg);
      background-size: 28px;
    `};
  :hover {
    cursor: pointer;
  }
  transition: transform 0.3s ease;
  :active {
    transform: scale(1.2);
  }
`
const NextHolder = styled.div`
  width: auto;
  font-size: 15px;
  display: flex;
  justify-content: end;
  :hover {
    cursor: pointer;
    i {
      font-weight: bold;
    }
  }
`

const FullListText = styled.span`
  line-height: 1.9;
  :hover {
    text-decoration: underline;
  }
`

const getOccupationPercent = (all, part) =>
  all > 0 ? Math.round((part / all) * 100) : 0

const checkIfRegisteredForTheDay = (attendanceArray, buildingId) => {
  return attendanceArray.find((item) => item.buildingId === buildingId)
}
const getFavoriteDetails = (selectedUsers, searchedId) => {
  return selectedUsers.find((user) => user.id === searchedId)
}
const MainInfoCard = ({
  dailyAttendaceData,
  selectDate,
  currentLocale,
  capacity,
  selectedDate,
  registerAttendanceAction,
  deleteRegistration,
  openDetails,
  buildingId,
  selectedUsers,
  showMessage,
  deleteMessageToShow,
  selfAttendances,
  currentBuilding,
}) => {
  const infoCardDate = getLocalDate(dailyAttendaceData.date)
  const dateValues = format(infoCardDate, 'EEE/MMM/dd', {
    locale: currentLocale,
  }).split('/')
  const popupDate = infoCardDate

  const checkTime = (attendanceTime) => {
    return (
      format(fromUnixTime(new Date(attendanceTime * 60)), 'dd/MM/yy') ===
      format(infoCardDate, 'dd/MM/yy')
    )
  }

  const attendanceForTheDay = selfAttendances.find((attendance) =>
    checkTime(attendance.start),
  )

  const attendanceCount = dailyAttendaceData.attendanceUtilization.peak
  const desksCount = dailyAttendaceData.deskUtilization.peak
  const peopleOcc = `${getOccupationPercent(
    capacity.people,
    attendanceCount,
  )} %`
  const activeRow =
    format(selectedDate, 'dd/MM/yy') === format(infoCardDate, 'dd/MM/yy')
  const desksOcc = `${getOccupationPercent(capacity.desks, desksCount)} %`

  const { selectedAttendance } = dailyAttendaceData
  const mappedAttendance = mapAttendanceTypes(selectedAttendance, buildingId)
  const sortedAttendance = sortByTypeAndName(mappedAttendance)

  const handleAttendanceType = (attendance) => {
    if (!attendance) return null
    const { type, buildingId } = attendance
    if (type === OFFICE || type === DESK)
      return currentBuilding === buildingId ? SAME_BUILDING : OTHER_BUILDING
    return type
  }
  const attendanceType = handleAttendanceType(attendanceForTheDay)
  if (showMessage.length > 0) {
    setTimeout(() => {
      deleteMessageToShow()
    }, 3000)
  }
  return (
    <CardItem onClick={() => selectDate(infoCardDate)} active={activeRow}>
      <MainDataHolder>
        <DateHolder>
          <p>{dateValues[0]}</p>
          <p>
            {dateValues[1]} {dateValues[2]}
          </p>
        </DateHolder>
        <ColleaguesHolder>
          {sortedAttendance.map((attendance, index) => {
            const searchedUser = getFavoriteDetails(
              selectedUsers,
              attendance.user.id,
            )
            if (searchedUser && index < 6)
              return (
                <FlowPlannerProfilePicture
                  key={index}
                  attendanceType={attendance.type}
                  user={searchedUser}
                >
                  <ColleaguePicture
                    id={searchedUser.id}
                    fn={searchedUser ? searchedUser.fn : ''}
                    ln={searchedUser ? searchedUser.ln : ''}
                  />
                </FlowPlannerProfilePicture>
              )
            return null
          })}
        </ColleaguesHolder>
        <RegisterAttendanceHolder>
          <RegisterAttendance
            registered={attendanceForTheDay}
            type={attendanceType}
            onClick={() =>
              attendanceForTheDay
                ? deleteRegistration(attendanceForTheDay.id)
                : registerAttendanceAction(popupDate)
            }
          />
        </RegisterAttendanceHolder>
        {showMessage.length > 0 && activeRow && (
          <AttendanceInfoBox showMessage={showMessage} />
        )}
      </MainDataHolder>
      <StatisticsHolder>
        <StatisticsItem value={desksOcc} hasIcon iconName={'DESK'} />
        <StatisticsItem value={peopleOcc} iconName={'building'} />
        <NextHolder
          onClick={(e) => {
            openDetails({
              attendanceCount,
              date: infoCardDate,
              selectedAttendance: dailyAttendaceData.selectedAttendance,
              selfAttendance: dailyAttendaceData.selfAttendance,
            })
            e.stopPropagation()
          }}
        >
          <FullListText>
            <Translate id="flowPlanner.fullList" />
          </FullListText>
          <FullListIcon name="back" />
        </NextHolder>
      </StatisticsHolder>
    </CardItem>
  )
}

MainInfoCard.propTypes = {
  dailyAttendaceData: PropTypes.object,
  selectDate: PropTypes.func,
  currentLocale: PropTypes.object,
  selectedDate: PropTypes.instanceOf(Date),
  capacity: PropTypes.object,
  registerAttendanceAction: PropTypes.func,
  deleteRegistration: PropTypes.func,
  openDetails: PropTypes.func,
  selectedUsers: PropTypes.array,
  buildingId: PropTypes.number,
  deleteMessageToShow: PropTypes.func,
  showMessage: PropTypes.string,
  selfAttendances: PropTypes.array,
  attendanceDetailsUsersOnly: PropTypes.array,
  currentBuilding: PropTypes.number,
}
const mapStateToProps = (state) => ({
  selectedUsers: getSelectedColleagues(state),
  selectedDate: getDateFilter(state),
  selfAttendances: getSelfAttendances(state),
  attendanceDetailsUsersOnly: getAttendanceDetailsUsersOnly(state),
  currentBuilding: getCurrentBuildingId(state),
})

export default connect(mapStateToProps, { deleteMessageToShow })(MainInfoCard)
