import { combineReducers } from 'redux-immutable'

import {
  MEETING_REQUEST,
  MEETING_SUCCESS,
  MEETING_FAILURE,
  CLOSE_BOOKING_UI,
  MEETING_SELECT,
  END_MEETING_REQUEST,
  END_MEETING_SUCCESS,
  END_MEETING_FAILURE,
  FETCH_MEETING_SLOTS_SUCCESS,
  SET_MEETING_DURATION,
} from 'containers/quickView/spaceModal/bookingFlow/constants'
import {
  CLEAR_QUICK_VIEW,
  SELECT_SPACE_ID,
} from 'containers/quickView/constants'

const isFetching = (state = false, action) => {
  switch (action.type) {
    case MEETING_REQUEST:
    case END_MEETING_REQUEST:
      return true
    case MEETING_SUCCESS:
    case MEETING_FAILURE:
    case END_MEETING_SUCCESS:
    case CLEAR_QUICK_VIEW:
      return false
    default:
      return state
  }
}

const status = (state = 'closed', action) => {
  switch (action.type) {
    case CLOSE_BOOKING_UI:
    case CLEAR_QUICK_VIEW:
    case SELECT_SPACE_ID:
      return 'closed'
    case MEETING_SELECT:
      return 'select'
    case MEETING_SUCCESS:
    case END_MEETING_FAILURE:
      return 'success'
    case MEETING_FAILURE:
      return 'failure'
    case END_MEETING_SUCCESS:
      return 'cancelSuccess'
    default:
      return state
  }
}

const currentMeeting = (state = '', action) => {
  switch (action.type) {
    case MEETING_SUCCESS:
      return action.meeting
    case END_MEETING_SUCCESS:
      return ''
    default:
      return state
  }
}

const error = (state = '', action) => {
  if (action.type === MEETING_FAILURE) {
    return action.error
  }
  return state
}

const meetingSlots = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MEETING_SLOTS_SUCCESS:
      return action.meetingSlots
    case SELECT_SPACE_ID:
      return {}
    default:
      return state
  }
}

const desiredMeetingLength = (state = { duration: 0 }, action) => {
  switch (action.type) {
    case SET_MEETING_DURATION:
      return {
        duration: action.duration,
      }
    case SELECT_SPACE_ID:
      return {
        duration: 0,
      }
    default:
      return state
  }
}

export default combineReducers({
  isFetching,
  status,
  error,
  currentMeeting,
  meetingSlots,
  desiredMeetingLength,
})
