import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'

import PanelDrawer from 'containers/mainPanel/PanelDrawer'
import { icons } from 'components/icon/icons'
import LocalePicker from 'containers/mainPanel/LocalePicker'
import LogoutButton from 'containers/mainPanel/LogoutButton'
import AngleButtons from 'containers/mainPanel/AngleButtons'
import VisibilityPicker from 'containers/mainPanel/VisibilityPicker'
import TimeFormatPicker from 'containers/mainPanel/TimeFormatPicker'
import VersionInformation from 'containers/mainPanel/VersionInformation'
import { setCameraAngle } from 'api/unity'
import { clearInnerDrawer } from 'containers/mainPanel/panelDrawer/actions'
import { getSelfVisibility } from 'containers/app/selectors'
import { EVERYONE, INVISIBLE } from 'containers/mainPanel/visibility/constants'
import { changeVisibility } from 'containers/mainPanel/visibility/actions'

const SettingsPicker = ({ visibility, changeVisibility }) => {
  const [currentAngle, setCurrentAngle] = useState('3d')
  const [visiblityToggleValue, setVisiblityToggleValue] = useState(false)

  useEffect(() => {
    setVisiblityToggleValue(visibility === EVERYONE)
  }, [visibility])

  const onVisibilityChange = (value) => {
    const newVisibility = value ? INVISIBLE : EVERYONE
    changeVisibility(newVisibility)
    setVisiblityToggleValue(!value)
  }

  const handleAngleSelect = (angle) => {
    setCameraAngle(angle)
    clearInnerDrawer()
    setCurrentAngle(angle)
  }
  return (
    <Translate>
      {({ translate }) => (
        <PanelDrawer
          iconName={icons.SETTINGS}
          label={translate('filter.title.settings')}
          allowToOpen
        >
          <VisibilityPicker
            isVisible={visiblityToggleValue}
            setVisibility={onVisibilityChange}
          />
          <TimeFormatPicker />
          <LocalePicker />
          <AngleButtons angle={currentAngle} setAngle={handleAngleSelect} />
          <LogoutButton />
          <VersionInformation />
        </PanelDrawer>
      )}
    </Translate>
  )
}

SettingsPicker.propTypes = {
  visibility: PropTypes.string,
  changeVisibility: PropTypes.func,
}

const mapStateToProps = (state) => ({
  visibility: getSelfVisibility(state),
})
export default connect(mapStateToProps, {
  clearInnerDrawer,
  changeVisibility,
})(SettingsPicker)
