import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import {
  getStatus,
  getHasClaimOnBehalfPermission,
} from 'containers/quickView/spaceModal/claimFlow/selectors'
import DeskClaimOnBehalfButton from 'containers/quickView/spaceModal/claimFlow/DeskClaimOnBehalfButton'
import {
  openClaimOnBehalfUI,
  closeClaimUI,
} from 'containers/quickView/spaceModal/claimFlow/actions'
import { getDateFilter } from 'containers/searchPanel/filter/selectors'
import DeskSelect from 'containers/quickView/spaceModal/claimFlow/DeskSelect'

const BoboFlow = ({
  hasClaimOnBehalfPermission,
  openClaimOnBehalfUI,
  status,
  dateSelected,
  handleBooking,
  isFetching,
  children,
}) => {
  const isBooked = true
  const handleClaimOnBehalf = () => {
    if (hasClaimOnBehalfPermission) {
      openClaimOnBehalfUI()
    }
  }

  return (
    <>
      {status === 'selectOnBehalf' ? (
        <DeskSelect
          isFetching={isFetching}
          onBooking={(user) => handleBooking(user.id)}
          onClose={closeClaimUI}
        />
      ) : (
        <>
          {' '}
          {hasClaimOnBehalfPermission ? (
            <>
              <>{children}</>
              <DeskClaimOnBehalfButton
                isFetching={isFetching}
                isBooked={isBooked}
                hasPermission={hasClaimOnBehalfPermission}
                dateSelected={dateSelected}
                onClaim={handleClaimOnBehalf}
              />
            </>
          ) : (
            <>{children}</>
          )}
        </>
      )}
    </>
  )
}

BoboFlow.propTypes = {
  isFetching: PropTypes.bool,
  handleBooking: PropTypes.func,
  hasClaimOnBehalfPermission: PropTypes.bool,
  dateSelected: PropTypes.instanceOf(Date),
  openClaimOnBehalfUI: PropTypes.func,
  closeClaimUI: PropTypes.func,
  status: PropTypes.string,
  children: PropTypes.node,
}

const mapStateToProps = (state) => ({
  hasClaimOnBehalfPermission: getHasClaimOnBehalfPermission(state),
  dateSelected: getDateFilter(state),
  status: getStatus(state),
})

export default connect(mapStateToProps, {
  openClaimOnBehalfUI,
  closeClaimUI,
})(BoboFlow)
